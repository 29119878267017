<template>
    <div>
        <div v-if="resetSection" class="resetPwdSection">
            <h2 class="clarity-h2 blue clarity-margin-bottom-m">Password Reset</h2>
            <p class="body-text clarity-margin-bottom-s">Please enter your new password, and confirm, in the boxes below.</p>
            <div v-if="showErrorBox" id="error-message-box" class="clarity-margin-bottom-s">
                <div class="flex justify-content-between">
                    <div class="flex">
                        <h3 class="clarity-h3"><span class="material-icons">error</span>PASSWORD RESET!</h3>
                    </div>
                    <div class="flex grey cursor-pointer" @click="closeErrorMsg">
                        <span class="material-icons-outlined">close</span>
                    </div>
                </div>
                <p class="body-text">{{ passwordErrorMessage }}</p>
            </div>
            <div class="formgrid grid">
                <div class="field col p-input-icon-left p-input-icon-right cl-input clarity-margin-bottom-xs">
                    <i class="pi cl-password-grey-icon" />
                    <InputText :type="hiddenPasswordOne" placeholder="Password..." class="p-inputtext-lg w-full" v-model="passwordOne" @keyup="checkPasswordOne" />
                    <i v-if="hiddenPasswordOne == 'password'" @click="revealPassword(1)" class="pi cl-show-password-grey-icon" />
                    <i v-else @click="revealPassword(1)" class="pi cl-hide-password-grey-icon" />
                </div>
            </div>

            <div :class="{ passwordStrengthBarRed: passwordStrength == 20, passwordStrengthBarDarkOrange: passwordStrength == 40, passwordStrengthBarOrange: passwordStrength == 60, passwordStrengthBarGreen: passwordStrength > 60}">
                <ProgressBar :value="passwordStrength" :showValue="false"></ProgressBar>
            </div>

            <p v-if="passwordStrength != 100" class="small-text blue clarity-margin-top-xs">To make your password stronger:</p>
            <div class="flex flex-wrap">
                <div v-if="!pwdLength" class="flex" style="width: 190px;">
                    <ul>
                        <li>Make it at least 8 characters.</li>
                    </ul>
                </div>
                <div v-if="!pwdLowercase" class="flex" style="width: 190px;">
                    <ul>
                        <li>Add lowercase letters.</li>
                    </ul>
                </div>
                <div v-if="!pwdUppercase" class="flex" style="width: 190px;">
                    <ul>
                        <li>Add uppercase letters.</li>
                    </ul>
                </div>
                <div v-if="!pwdNumber" class="flex" style="width: 190px;">
                    <ul>
                        <li>Add numbers.</li>
                    </ul>
                </div>
                <div v-if="!pwdPunctuation" class="flex" style="width: 190px;">
                    <ul>
                        <li>Add punctuation.</li>
                    </ul>
                </div>
            </div>
            <div class="formgrid grid">
                <div class="field col p-input-icon-left p-input-icon-right cl-input clarity-margin-top-xs clarity-margin-bottom-s">
                    <i class="pi cl-password-grey-icon" />
                    <InputText :type="hiddenPasswordTwo" placeholder="Confirm Password..." class="p-inputtext-lg w-full" v-model="passwordTwo" />
                    <i v-if="hiddenPasswordTwo == 'password'" @click="revealPassword(2)" class="pi cl-show-password-grey-icon" />
                    <i v-else @click="revealPassword(2)" class="pi cl-hide-password-grey-icon" />
                </div>
            </div>
            <div class="flex-1 justify-content-center" align="left">
                <Button @click="passwordReset" label="Reset Password" class="mr-2 mb-2 clarity-gradient-orange clarity-btn" style="width: 290px;" />
            </div>
            <div class="hr-line"></div>
            <p class="body-text dark-grey clarity-no-margin">Need help?</p>
            <p class="small-text grey">Find out more about "How to reset your Password".</p>
        </div>
        <div class="reset-success" v-else>
            <h2 class="clarity-h2 blue">Password Reset</h2>
            <div class="flex flex-column clarity-margin-top-m">
                <div v-if="showConfirmationBox" id="reset-success-box" class="clarity-margin-bottom-s">
                    <div class="flex justify-content-between">
                        <div class="flex">
                            <h3 class="clarity-h3"><span class="material-icons">error</span>PASSWORD RESET!</h3>
                        </div>
                        <div class="flex grey cursor-pointer" @click="closeResetSuccessMsg">
                            <span class="material-icons-outlined">close</span>
                        </div>
                    </div>
                    <p class="body-text dark-grey">Your password has been reset successfully.</p>
                </div>
                <Button @click="backToLogin" label="Back to Login" class="clarity-gradient-orange clarity-btn" style="width: 300px;" />
                <div class="hr-line"></div>
                <p class="body-text dark-grey clarity-no-margin">Need help?</p>
                <p class="small-text grey">if you have any problems, please send an email to <a href="mailto:enquiries@clarityglobal.com">enquiries@clarityglobal.com</a>.</p>
            </div>
        </div>
    </div>
</template>

<script>
    import { Auth } from '@aws-amplify/auth';
    import api from "@/core/services/api";
    import { useRouter } from 'vue-router';
    import { ref } from 'vue';
    import {toClarityAnonRequest} from "../core/helpers";

    export default {
        setup() {
            const router = useRouter();
            const code = ref('');
            const username =  ref('');
            const resetAPImessages = ref('');
            const passwordErrorMessage = ref('');
            const email = ref();
            const confEmail = ref();
            const passwordOne = ref('');
            const passwordTwo = ref('');
            const passwordStrength = ref(0);
            const pwdLowercase = ref(false);
            const pwdUppercase = ref(false);
            const pwdNumber = ref(false);
            const pwdPunctuation = ref(false);
            const pwdLength = ref(false);
            const hiddenPasswordOne = ref('password');
            const hiddenPasswordTwo = ref('password');
            const resetSection = ref(true);
            const showConfirmationBox = ref(false);
            const showErrorBox = ref(false);

            const checkPasswordOne = () => {
                const pwd = passwordOne.value;

                if(pwd.match(/[a-z]/g)) {
                    pwdLowercase.value = true;
                } else {
                    pwdLowercase.value = false;
                }
                if(pwd.match(/[A-Z]/g)) {
                    pwdUppercase.value = true;
                } else {
                    pwdUppercase.value = false;
                }
                if(pwd.match(/[0-9]/g)) {
                    pwdNumber.value = true;
                } else {
                    pwdNumber.value = false;
                }
                if(pwd.match(/[\W|_]/g)) {
                    pwdPunctuation.value = true;
                } else {
                    pwdPunctuation.value = false;
                }
                if(pwd.length > 7) {
                    pwdLength.value = true;
                } else {
                    pwdLength.value = false;
                }

                passwordStrength.value = 0;
                if(pwdLowercase.value) {
                    passwordStrength.value += 20;
                }
                if(pwdUppercase.value) {
                    passwordStrength.value += 20;
                }
                if(pwdNumber.value) {
                    passwordStrength.value += 20;
                }
                if(pwdPunctuation.value) {
                    passwordStrength.value += 20;
                }
                if(pwdLength.value) {
                    passwordStrength.value += 20;
                }
            }

            const revealPassword = (elm) => {
                if(elm == 1) {
                    if(hiddenPasswordOne.value == 'password') {
                        hiddenPasswordOne.value = 'text';
                    } else {
                        hiddenPasswordOne.value = 'password';
                    }
                } else if (elm == 2) {
                    if(hiddenPasswordTwo.value == 'password') {
                        hiddenPasswordTwo.value = 'text';
                    } else {
                        hiddenPasswordTwo.value = 'password';
                    }
                }
            }

            const checkPasswordTwo = () => {
                if(passwordOne.value == passwordTwo.value) {
                    return true;
                } else {
                    return false;
                }
            }

            const passwordReset = async () => {
                if(showErrorBox.value) {
                    showErrorBox.value = false;
                }
                const URI = decodeURIComponent(window.location.href);
                const params = new URL(URI).searchParams;
                const encodedUsername = params.get('u');
                const decodedUsername = Buffer.from(encodedUsername, 'base64').toString('utf8');
                code.value = params.get('c');
                username.value = decodedUsername;
                const validPassword = checkPasswordTwo();
                if(validPassword) {
                    if(passwordStrength.value > 60) {
                        const result = await finishRecover(code.value,username.value,passwordTwo.value);
                        const resultString = String(result);
                        const isSuccess = resultString.includes('SUCCESS');
                        if(isSuccess) {
                            resetSection.value = false;
                            showConfirmationBox.value = true;
                            const endpoint = '/anon/passwordresetemail';
                            const result = await api.post(`${toClarityAnonRequest(endpoint)}`,
                            JSON.stringify({'email':username.value}),
                            {
                                headers: {
                                    'Content-Type': 'application/json'
                                }
                            });
                            console.log(result);
                        } else {
                            showErrorBox.value = true;
                        }
                    } else {
                        passwordErrorMessage.value = 'Password too weak. Please check password guidelines.';
                        showErrorBox.value = true;
                    }
                } else {
                    passwordErrorMessage.value = 'Passwords do not match';
                    showErrorBox.value = true;
                }
            }

            const finishRecover = async (code,username,password) => {
                await Auth.forgotPasswordSubmit(username, code, password)
                    .then(data => {
                        resetAPImessages.value = data;
                    })
                    .catch(err => {
                        resetAPImessages.value = err;
                        passwordErrorMessage.value = err;
                    });
                return resetAPImessages.value;
            }

            const closeResetSuccessMsg = () => {
                if(showConfirmationBox.value) {
                    showConfirmationBox.value = false;
                }
            }

            const closeErrorMsg = () => {
                if(showErrorBox.value) {
                    showErrorBox.value = false;
                }
            }

            const backToLogin = () => {
                router.push('/');
            }

            return {
                passwordReset,
                finishRecover,
                closeResetSuccessMsg,
                backToLogin,
                checkPasswordOne,
                checkPasswordTwo,
                revealPassword,
                closeErrorMsg,
                resetSection,
                showConfirmationBox,
                showErrorBox,
                passwordErrorMessage,
                hiddenPasswordOne,
                hiddenPasswordTwo,
                email,
                confEmail,
                passwordOne,
                passwordTwo,
                passwordStrength,
                pwdLowercase,
                pwdUppercase,
                pwdNumber,
                pwdPunctuation,
                pwdLength
            }
        }
    }
</script>

<style>
    ul {
        margin-bottom: 0px;
    }
    li {
        margin-bottom: 10px;
    }
    .login-content {
        margin-bottom: 40px !important;
    }
    .resetPwdSection {
        width: 610px;
        margin: 0px auto;
    }
    .passwordInputBox {
        margin-bottom: 20px;
        border-radius: 30px;
        border: solid 1px #DCE1E6;
        height: 54px;
        width: 610px;
        font-size: 18px;
        background-color: white;
        background-image: url('../assets/icons/password-icon.png');
        background-position: 25px 15px;
        background-repeat: no-repeat;
        padding-left: 60px;
    }
    .clarity-margin-left-el {
        margin-left: 110px;
    }
    #error-message-box {
        width: 610px;
        padding: 30px 20px 35px 30px;
        background: transparent linear-gradient(180deg, #F2686E33 0%, #D131384D 100%) 0% 0% no-repeat padding-box;
        box-shadow: 0px 5px 20px #0000000D;
        border: 1px solid #D1313880;
        border-radius: 27px;
    }
    #error-message-box p {
        margin-left: 35px;
        margin-bottom: 0px;
        color: var(--clarity-dark-grey);
    }

    #error-message-box h3 {
        color: var(--clarity-red);
    }
    #reset-success-box {
        width: 610px;
        padding: 30px 20px 35px 30px;
        background: transparent linear-gradient(180deg, #1CD37F33 0%, #0195674D 100%) 0% 0% no-repeat padding-box;
        box-shadow: 0px 5px 20px #0000000D;
        border: 1px solid #01956780;
        border-radius: 27px;
    }
    #reset-success-box p {
        margin-left: 35px;
        margin-bottom: 0px;
    }
    #reset-success-box h3 {
        color: var(--clarity-dark-green);
    }
    .material-icons {
        vertical-align: top;
        margin-right: 10px !important;
    }
    .hr-line {
        margin: 45px 0px 45px 0px;
        border-bottom: 1px solid var(--clarity-dark-grey);
    }
    .reset-success a {
        text-decoration: underline;
        color: var(--clarity-grey);
    }

    @media only screen and (max-width: 430px) {
        .resetPwdSection {
            width: 400px;
        }
        #error-message-box, #reset-success-box {
            width: 400px;
        }
    }

    @media only screen and (max-width: 425px) {
        .resetPwdSection {
            width: 335px;
        }
        #error-message-box, #reset-success-box {
            width: 335px;
        }
    }

    @media only screen and (max-width: 320px) {
        .resetPwdSection {
            width: 310px;
        }
        #error-message-box, #reset-success-box {
            width: 310px;
        }
    }
</style>
